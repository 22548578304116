<template>
  <div>
    <div class="flex-row items-end flex justify-end ">

      <vs-button class="ml-4 mb-3" @click="viewAllData()">{{ viewAll ? $t('ShowLess') : $t('ViewAll') }}</vs-button>
      <vs-button class="ml-4 mb-3" color="danger" @click="$emit('close')">{{ $t('Close') }}</vs-button>
    </div>
<!--    {{ selectedProduct }}-->
<!--    {{ filters }}-->
<!--    {{ customerProducts.length }}-->
<!--    {{ customerProducts }}-->
    <vs-table-modified v-show="!errorFetching" ref="table"
                       :max-items="10"
                       :data="customerProducts">
      <template slot="thead">
        <vs-th>
          <div class="vs-col">
            <p class="">{{ $t('Customer') }}</p>
          </div>
        </vs-th>
        <vs-th>
          <div class="vs-col">
            <p class="">{{ $t('Product') }}</p>
          </div>
        </vs-th>
        <vs-th>
          <div class="vs-col">
            <p class="">{{ $t('Denomination') }}</p>
          </div>
        </vs-th>
        <vs-th>
          <div class="vs-col">
            <p class="">{{ $t('ProductType') }}</p>
          </div>
        </vs-th>
        <vs-th>
          <div class="vs-col">
            <p class="">{{ $t('Quantity') }}</p>
          </div>
        </vs-th>
        <vs-th>
          <p style="display: inline-flex;"
             @click="localSortUpdate('by_total_buying_amount')">
            <span style="line-height: 24px;">{{ $t('TotalBuyingAmount') }}</span>
            <span class="material-icons"
                  v-if="localSortKey === 'by_total_buying_amount' && localSortOrder == 0">
                      arrow_drop_down
                    </span>
            <span class="material-icons"
                  v-if="localSortKey === 'by_total_buying_amount' && localSortOrder == 1">
                      arrow_drop_up
                    </span>
          </p>
        </vs-th>
        <vs-th>
          <p style="display: inline-flex;" @click="localSortUpdate('by_total_sale_amount')">
            <span style="line-height: 24px;">{{ $t('TotalSaleAmount') }}</span>
            <span class="material-icons"
                  v-if="localSortKey === 'by_total_sale_amount' && localSortOrder == 0">
                      arrow_drop_down
                    </span>
            <span class="material-icons"
                  v-if="localSortKey === 'by_total_sale_amount' && localSortOrder == 1">
                      arrow_drop_up
                    </span>
          </p>
        </vs-th>
        <vs-th>
          <div class="vs-col">
            <p class="">{{ $t('Profit') }}</p>
          </div>
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="flex flex-col justify-center">
              <p class="font-medium truncate">{{ tr.customer_id }}</p>
              <p class="font-medium truncate">{{ tr.first_name }} {{ tr.last_name }}</p>
              <p style="font-size: 11px;">
                {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
              </p>

              <p style="font-size: 11px;">
                {{ tr.postal }} {{ tr.city }}
              </p>
            </div>
          </vs-td>
<!--          <vs-td>-->
<!--            <div class="vs-col">-->
<!--              <p class="font-medium truncate">{{ tr.customer_id }}</p>-->
<!--            </div>-->
<!--          </vs-td>-->
          <vs-td>
            <div class="vs-col">
              <p class="font-medium truncate">{{ tr.product_name }}</p>
            </div>
          </vs-td>
          <vs-td>
            <div class="vs-col">
              <p class="font-medium truncate">{{ tr.value | germanNumberFormat }}</p>
            </div>
          </vs-td>
          <vs-td>
            <div class="vs-col">
              <p class="font-medium truncate">{{ tr.product_type }}</p>
            </div>
          </vs-td>
          <vs-td>
            <div class="vs-col">
              <p v-if="tr.total_quantity >= 0 || !fetchingSalesData">
                {{ tr.total_quantity }}
              </p>
              <p v-else>
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </p>
            </div>
          </vs-td>
          <vs-td>
            <p v-if="tr.total_buying_amount >= 0 || !fetchingSalesData">
              {{ tr.total_buying_amount | germanNumberFormat }}
            </p>
            <p v-else>
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </p>
          </vs-td>
          <vs-td>
            <p v-if="tr.total_sale_amount >= 0 || !fetchingSalesData">
              {{ tr.total_sale_amount | germanNumberFormat }}
            </p>
            <p v-else>
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </p>
          </vs-td>
          <vs-td>
            <div class="vs-col">
              <p v-if="tr.total_profit >= 0 || !fetchingSalesData">
                {{ tr.total_profit | germanNumberFormat }}
              </p>
              <p v-else>
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                          stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </p>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
      <template slot="footer">
      </template>
      <template slot="footer-left">
        <div class="flex">
          <vs-new-pagination :total="vTotalPages" v-model="currentPage"
                             v-on:change="listPageChanged">
          </vs-new-pagination>
        </div>
      </template>
    </vs-table-modified>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SalesSummaryReportDetailsDialog",
  data() {
    return {
      viewAll: false,
      cancelRequest: null,
      currentPage: 1,
      errorFetching: false,
      fetchingSalesData: false,
      customerItemIndex: 0,
      localSortKey: '',
      localSortOrder: -1,
    }
  },
  props: {
    filters: {
      type: Object,
    },
    selectedProduct: {
      type: Object,
    },
  },
  computed: {

    vPage() {
      return this.$store.state.salesSummaryReport.vPage2
    },
    vTotalCount() {
      return this.$store.state.salesSummaryReport.vTotalCount2
    },
    vTotalPages() {
      return this.$store.state.salesSummaryReport.vTotalPages2
    },
    customerProducts() {
      console.log(this.selectedProduct);
      const data = []
      data.push(...this.$store.state.salesSummaryReport.customerDetails)
      if (!this.fetchingSalesData) {
        if (this.localSortKey === "by_total_buying_amount" && this.localSortOrder !== -1) {
          data.sort((a, b) => {
            return this.localSortOrder === 0 ? a.total_buying_amount - b.total_buying_amount : b.total_buying_amount - a.total_buying_amount
          });
        }
        if (this.localSortKey === "by_total_sale_amount" && this.localSortOrder !== -1) {
          data.sort((a, b) => {
            return this.localSortOrder === 0 ? a.total_sale_amount - b.total_sale_amount : b.total_sale_amount - a.total_sale_amount
          });
        }
      }
      return data;
    },
  },
  methods: {
    localSortUpdate(key) {
      if (key === this.localSortKey) {
        if (this.localSortOrder == -1) {
          this.localSortOrder = 0;
        } else if (this.localSortOrder == 0) {
          this.localSortOrder = 1;
        } else if (this.localSortOrder == 1) {
          this.localSortOrder = -1;
        }
      } else {
        this.localSortOrder = 0
      }
      this.localSortKey = key;
    },
    updateSort(key) {

    },
    getFilters() {
      // if (this.selectedProduct) {
      //   filter.product_id = this.selectedProduct.product_configuration_id
      // }
      // if (this.selectedProductConfigId) {
      //   filter.product_configuration_id = this.selectedProductConfigId.replace('product_configuration_id', '')
      // }
      let filter = {
        ...this.filters,
        product_configuration_id: this.selectedProduct.product_configuration_id,
      };
      if(this.viewAll) {
        filter['view_all'] = this.viewAll ? 1 : 0;
      }
      return filter
    },
    listPageChanged(item) {
      this.loadCustomerProducts(this.currentPage - 1)
    },
    viewAllData() {
      this.viewAll = !this.viewAll;
      if (this.cancelRequest) {
        this.cancelRequest.cancel("reset-cancel");
        this.$store.dispatch('salesSummaryReport/resetCustomersSalesData');
        this.fetchingSalesData = false;
      }
      this.loadCustomerProducts()
    },
    loadCustomerProducts(page = '0') {
      if (page == 0) this.currentPage = 1
      const filters = this.getFilters();
      this.$vs.loading()
      this.$store.dispatch('salesSummaryReport/fetchSalesSummaryDetailCustomer', {
        filters,
        page,
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (this.cancelRequest) {
            this.cancelRequest.cancel("reset-cancel");
            this.$store.dispatch('salesSummaryReport/resetCustomersSalesData');
            this.fetchingSalesData = false;
          }
          this.customerIndex = 0;
          this.updateData();
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-cixrcle',
            color: 'danger',
          })
        })
    },
    updateData() {
      const filters = this.getFilters();
      // if(this.customerIndex == -1 || this.customerIndex == 0) {
      //   this.$store.dispatch('salesSummaryReport/resetCustomersSalesData');
      //   this.customerIndex = 0;
      // }
      if (!this.customerProducts[this.customerIndex]) {
        this.fetchingSalesData = false;
        return
      }
      this.cancelRequest = axios.CancelToken.source();
      if(this.customerProducts[this.customerIndex].customer_id) {
        filters.customer_id = this.customerProducts[this.customerIndex].customer_id
      }
      filters.product_configuration_id = this.customerProducts[this.customerIndex].product_configuration_id
      if(this.customerProducts[this.customerIndex].super_admin_discount != undefined) {
        filters.super_admin_discount = this.customerProducts[this.customerIndex].super_admin_discount
      }
      this.fetchingSalesData = true;

      this.$store.dispatch('salesSummaryReport/getSalesSummaryDataWithCustomers', {
        filters,
        cancelToken: this.cancelRequest.token,
        type: 2 // 2 for Details
      }).then((data) => {
        this.customerIndex += 1;
        if (this.customerIndex < this.customerProducts.length) {
          this.updateData();
        } else {
          this.fetchingSalesData = false;
        }
      })
    },
  },
  mounted() {
    // this.loadCustomerProducts();
  }
}
</script>

<style scoped>

</style>
